import React, { createContext, useState, useRef, useEffect } from "react";
import { api } from "@/utils/axiosInstance.js";
import { removeSelectedPolygons } from "./helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBalance, useAccount } from "wagmi";

const MapContext = createContext();

const MapProvider = ({ children }) => {
  const [selectedCellsHistory, setSelectedCellsHistory] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [coordinates, setCoordinates] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [userOwnedTiles, setUserOwnedTiles] = useState([]);
  const [openTileModal, setOpenTileModal] = useState(false);

  const [landInfo, setLandInfo] = useState(null);
  const viewerRef = useRef(null);
  const handlerRef = useRef(null);
  const { id } = useParams();
  const { address } = useAccount();
  const navigate = useNavigate();
  const undoHandler = () => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;

      setSelectedCellsHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        const lastState = newHistory.pop();
        if (lastState) {
          const newSelectedCells = selectedCells.filter(
            (item) =>
              !lastState.some((tile) => tile.identifier === item.identifier)
          );

          removeSelectedPolygons(viewer, lastState);
          setSelectedCells(newSelectedCells);
          setClickCount((prevCount) => Math.max(prevCount - 1, 0));
        }
        if (newHistory.length === 0) {
          setSelectedCells([]);
        }
        return newHistory;
      });
    }
  };
  const toggleModal = () => {
    setOpenTileModal(!openTileModal);
  };
  const emptySelectedCells = () => {
    setSelectedCells([]);
    setOpenTileModal(false);
    setClickCount(0);
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      removeSelectedPolygons(viewer, selectedCells);
    }
  };
  const tileNumber = selectedCells.length;

  const getLandInfo = () => {
    api
      .get(`/fetch_land_details/?land_id=${id}&user_address=${address}`)
      .then((res) => {
        setLandInfo(res.data.data);
        if (
          !res.data.data.is_duplicated ||
          (res.data.data.under_review && res.data.data.is_duplicated)
        ) {
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getLandInfo();
  }, [id, address]);

  return (
    <MapContext.Provider
      value={{
        viewerRef,
        handlerRef,
        selectedCells,
        setSelectedCells,
        clickCount,
        setClickCount,
        undoHandler,
        coordinates,
        setCoordinates,
        isPurchased,
        setIsPurchased,
        userOwnedTiles,
        setUserOwnedTiles,
        openTileModal,
        emptySelectedCells,
        toggleModal,
        tileNumber,
        setSelectedCellsHistory,
        landInfo,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapProvider };
