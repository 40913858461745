import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckedRadio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#FFB70F"
      strokeWidth={1.5}
      d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z"
    />
    <path
      stroke="#7D7D7D"
      strokeWidth={1.5}
      d="M7.75 12a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0Z"
    />
    <path fill="#FFB70F" d="M7 11.998a5 5 0 1 1 10 0 5 5 0 0 1-10 0" />
  </svg>
);
export default SvgCheckedRadio;
