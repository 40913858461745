import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MapContext } from "../context.jsx";
import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";
import {
  HomeAngle,
  UserRounded,
  CartLargeMinimalistic,
  DangerCircle,
  Dollar,
  LayersMinimalistic,
  Trash,
  BuyLand,
} from "@/components/Icons";
import Button from "@/components/Common/Button";
import Accordion from "@/components/Common/Accordion";

interface Props {
  buyLandHandler: () => void;
  isLoading: boolean;
  profileBtnHandler: () => boolean | null;
}
const BuyLandMobileNavbar: React.FC<Props> = ({
  buyLandHandler,
  isLoading,
  profileBtnHandler,
}) => {
  const navigate = useNavigate();
  const {
    tileNumber,
    toggleModal,
    emptySelectedCells,
    openTileModal,
    landInfo,
  } = useContext(MapContext);
  const btnHandler = () => {
    if (profileBtnHandler) {
      profileBtnHandler();
    } else {
      navigate("/dashboard/profile");
    }
  };
  return (
    <div>
      <div className="fixed z-30 bottom-0 bg-blackshade-900 h-[64px] base-shaodw pt-3 w-full">
        <div>
          <div className="bg-horizontal-linear h-[1px] absolute top-0 w-1/2 right-0"></div>
          <div className="bg-horizontal-linear h-[1px] absolute top-0 w-1/2 left-0 rotate-180"></div>
          <div className="flex justify-around">
            <NavLink to="/">
              {({ isActive }) => (
                <div className="relative">
                  <HomeAngle
                    className={`h-6 2-6 ${
                      isActive ? "text-gold-500" : "text-blackshade-300"
                    }`}
                  />
                  {isActive && (
                    <div className="w-2 h-2 bg-gold-500 rounded-full blur-[1px] absolute  left-1/2 -translate-x-1/2 -bottom-[26px]"></div>
                  )}{" "}
                </div>
              )}
            </NavLink>
            <div onClick={btnHandler} className="cursor-pointer">
              <div className="relative">
                <UserRounded
                  className={`h-6 2-6 text-blackshade-300
                    }`}
                />
              </div>
            </div>
          </div>
          <div className="absolute -top-5 left-1/2 -translate-x-1/2 flex flex-col items-center">
            <Button
              model="main"
              size="sm"
              className="w-12 !h-12"
              onClick={toggleModal}
            >
              <BuyLand />
            </Button>
            <div className="text-body-md text-white mt-1">Buy Land</div>
          </div>
        </div>
      </div>
      <div
        className={`space-y-4 fixed bottom-20 left-4 right-4 rounded-[20px] bg-blackshade-900 shadow-frame p-3 transition-all z-10 ${
          openTileModal ? "translate-y-0" : "translate-y-[120%]"
        }`}
      >
        <div className="rounded-2xl bg-dashboard-frame p-3">
          <h2 className="text-label-lg desktop:text-subheading-md text-white">
            LAND INFO
          </h2>
        </div>
        <div className="rounded-2xl bg-dashboard-frame p-3">
          <div className=" flex justify-between items-center pr-2">
            <div className="flex gap-2 items-center">
              <LayersMinimalistic className="text-gold-500 w-5 h-5" />
              <h2 className="text-label-lg text-blackshade-200">
                Selected TILES
              </h2>
            </div>
            <div className="text-white text-body-lg  desktop:text-label-lg">
              <span className="text-gold-500">{tileNumber}</span> TILES
            </div>
          </div>
        </div>
        <div className="rounded-2xl bg-dashboard-frame p-3">
          <div className=" flex justify-between items-center pr-2">
            <DangerCircle className="text-white w-5 h-5" />
            <h2 className="text-label-lg text-blackshade-200">
              You must select {landInfo?.tiles_count} tiles.
            </h2>
          </div>
        </div>

        <div className="border-t border-blackshade-800 pt-3 mt-4 flex flex-row-reverse gap-3 desktop:block">
          <Button
            model="main"
            size="md"
            className="w-full"
            onClick={buyLandHandler}
            isLoading={isLoading}
          >
            <CartLargeMinimalistic />
            <span>Buy Now</span>
          </Button>
          <Button
            model="error"
            size="md"
            className="desktop:mt-4 w-full flex items-center text-label-lg"
            onClick={() => emptySelectedCells()}
          >
            <Trash />
            <span>Clear Selecttion</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BuyLandMobileNavbar;
