import { useContext, useEffect } from "react";
import { useAccount } from "wagmi";
import { useQueryClient } from "@tanstack/react-query";
import { MapContext } from "../context.jsx";
import BaseModal from "@/components/Common/BaseModal";
import Button from "@/components/Common/Button";
import Stepper from "@/components/Common/Stepper";
import TileInfo from "./components/TileInfo.jsx";
import TokenSelector from "./components/TokenSelector";
import { useLandActions } from "./hooks/useLandActions.js";
import { useLandState } from "./context";
import { stepperList } from "./helpers/stepperConfig.js";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/Common/Toast/utils";
import { api } from "@/utils/axiosInstance.js";
import { convertTilesToString } from "@/utils/landHelpers.js";

const BuyLandModal = ({ closeModalHandler, contractData }) => {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { address } = useAccount();
  const queryClient = useQueryClient();
  const {
    buyLandHandler,
    isDisabled,
    totalStatus,
    totalStatusTitle,
    totalRetryHandler,
    approve,
    transactionReceipt,
    approveHandler,
    purchaseLandHandler,
    purchaseLand,
    fetchBuyLandData,
  } = useLandActions({ closeModalHandler, contractData, address, queryClient });
  const { titleInfo, selectedCells, setIsPurchased } = useContext(MapContext);
  const {
    isLoading,
    showStepper,
    tokenPercentageValue,
    setSelectedTokenValue,
    selectedTokenValue,
    setTokenPercentageValue,
    fetchLandError,
    isFetchingLand,
    fetchLandData,
    setIsLoading,
  } = useLandState();
  const resetLockHandler = () => {
    api.post("/reset_lock/", { user_address: address });
  };
  const cancelBuyLand = async () => {
    try {
      await api.delete("/cancel_purchase/", {
        data: {
          tile_data: convertTilesToString(selectedCells),
          user_address: address,
        },
      });
    } catch (error) {
      console.error("Error canceling land purchase:", error);
    }
  };
  useEffect(() => {
    if (purchaseLand.isSuccess) {
      const redirectHandler = async () => {
        await resetLockHandler();
        addToast("success", "Land Purchase Done! Processing Now.");
        const timeoutId = setTimeout(() => {
          closeModalHandler();
          setIsPurchased(true);
          navigate("/dashboard/purchased-lands");
        }, 15000);

        // Cleanup function to clear timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      };

      redirectHandler();
    }
  }, [purchaseLand.isSuccess]);

  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        addToast("danger", "You rejected the spending cap request.");
        // closeModalHandler();
      }
    }
  }, [approve.isError]);

  useEffect(() => {
    if (purchaseLand.error) {
      cancelBuyLand();
      addToast(
        "danger",
        purchaseLand.error?.cause?.shortMessage || "Purchase failed."
      );
      // closeModalHandler();
      setIsLoading(false);
    }
  }, [purchaseLand.error]);


  return (
    <BaseModal
      title="Payment Options"
      closeModalHandler={closeModalHandler}
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          {totalStatus === "failed" && (
            <Button
              model="ghost"
              size="sm"
              onClick={closeModalHandler}
              className="!w-full desktop:!w-max"
            >
              Cancel
            </Button>
          )}
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            onClick={
              totalStatus === "failed" ? totalRetryHandler : buyLandHandler
            }
            disabled={isDisabled && totalStatus !== "failed"}
            isLoading={isLoading && totalStatus !== "failed"}
          >
            {totalStatus === "failed" ? "Try Again" : "Confirm"}
          </Button>
        </div>
      }
    >
      <Stepper
        list={stepperList({
          approve,
          transactionReceipt,
          approveHandler,
          isFetchingLand,
          fetchLandData,
          purchaseLandHandler,
          purchaseLand,
          fetchLandError,
          fetchBuyLandData,
        })}
        showStepper={showStepper}
        totalStatus={totalStatus}
        totalStatusTitle={totalStatusTitle}
      />
      {showStepper ? null : (
        <>
          <TileInfo
            tile_count={contractData.tile_count}
            tilePrice={titleInfo.tilePrice}
            // purchase_price_token={contractData.purchase_price_token}
          />
          <div className=" p-3 bg-dashboard-frame rounded-[20px] mt-4">
            <TokenSelector
              tile_count={contractData.tile_count}
              purchase_price_token={contractData.purchase_price_token}
              tokenPercentageValue={tokenPercentageValue}
              setTokenPercentageValue={setTokenPercentageValue}
              selectedTokenValue={selectedTokenValue}
              setSelectedTokenValue={setSelectedTokenValue}
            />
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default BuyLandModal;
