import React from "react";
import TableMobileCard from "@/components/Common/TableMobileCard";
import {
  Dollar,
  Earth,
  LayersMinimalistic,
  TagHorizontal,
} from "@/components/Icons";
import ActionButtons from "./ActionButtons";
import Button from "@/components/Common/Button";

interface Land {
  land_id: string;
  tiles_count: number;
  purchasepriceusd: string;
  purchasepricetoken: string;
  token_type: string;
  is_nft: boolean;
  is_duplicated: boolean;
  under_review: boolean;
  tiles_coordination: { latitude: string; longitude: string }[];
}

interface MobileTableProps {
  land: Land;
  handleConvertToNFT: () => void;
  ComingSoonMessage: () => void;
  link: string;
  isLoading?: boolean;
}
const MobileTable: React.FC<MobileTableProps> = ({
  land,
  ComingSoonMessage,
  handleConvertToNFT,
  link,
  isLoading,
}) => {
  return (
    <TableMobileCard
      header={
        <>
          <div className="flex gap-1 items-center pl-1">
            <TagHorizontal />
            <div className="text-body-lg">Land ID:</div>
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3  animate-pulse"></div>
            ) : (
              <span className="text-label-sm">{land.land_id} </span>
            )}
          </div>
          <Button disabled={isLoading} href={link} model="tonal" size="sm">
            <Earth className="mr-1 h-5 w-5" />
            Open in map
          </Button>
        </>
      }
      action={
        isLoading || !land?.is_nft ? (
          <div className="flex [&>button]:flex-1 gap-4">
            <ActionButtons
              isLoading={isLoading}
              land={land}
              handleConvertToNFT={handleConvertToNFT}
              ComingSoonMessage={ComingSoonMessage}
            />
          </div>
        ) : land.is_duplicated && !land.under_review ? (
          <Button
            model="outlined"
            size="sm"
            className="w-full"
            href={`/duplicate-lands/${land.land_id}?lat=${land.tiles_coordination[0].latitude}&lng=${land.tiles_coordination[0].longitude}`}
          >
            Select Tiles Again
          </Button>
        ) : land.is_duplicated && land.under_review ? (
          <Button model="outlined" size="sm" className="w-full" disabled>
            Processing ...
          </Button>
        ) : null
      }
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <LayersMinimalistic />
          <span>Number of Tiles</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
          ) : (
            land?.tiles_count
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <Dollar />
          <span>Purchase Price</span>
        </div>
        <div className="flex gap-3 justify-between items-center text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-14 h-3  animate-pulse"></div>
          ) : (
            <span> {land?.purchasepriceusd} USDT</span>
          )}
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-14 h-3  animate-pulse"></div>
          ) : (
            <span>
              {land?.purchasepricetoken} {land?.token_type}
            </span>
          )}
        </div>
      </div>
    </TableMobileCard>
  );
};

export default MobileTable;
