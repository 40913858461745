import React from "react";
import App from "./../app.jsx";

import Profile from "@/pages/Dashboard/Profile";
import PurchasedLands from "@/pages/Dashboard/PurchasedLands";
import Referees from "@/pages/Dashboard/Referees";
import MyNFTs from "@/pages/Dashboard/MyNFTs";
import MyStakings from "@/pages/Dashboard/MyStakings";

import PagesError from "@/pages/Public/Error";
import Home from "@/pages/Public/Home";
import Lands from "@/pages/Public/Lands";
import BlogDetails from "@/pages/Public/BlogDetails";
import TermsAndConditions from "@/pages/Public/TermsAndConditions";
import PrivacyPolicy from "@/pages/Public/PrivacyPolicy";
import Contracts from "@/pages/Public/Contracts";

import Signup from "@/pages/Auth/Signup";
import DashbaordLayout from "@/components/Layout/DashbaordLayout";
import LandingLayout from "@/components/Layout/HomeLayout";
import PublicRoute from "@/utils/publicRoute.js";

import { RouteObject } from "react-router-dom";
import ClaimedHistory from "@/components/Pages/Dashboard/ClaimedHistory";
import DuplicateLands from "@/pages/Dashboard/DuplicateLands";
import ProtectedLayout from "@/components/Layout/ProtectedLayout";

const AppRoute: RouteObject[] = [
  {
    path: "*",
    element: <App />,
    children: [
      { path: "", element: <LandingLayout element={<Home />} /> },
      {
        path: "lands",
        element: <LandingLayout element={<Lands />} />,
      },
      {
        path: "blog/:id",
        element: <LandingLayout element={<BlogDetails />} />,
      },
      {
        path: "privacy-policy",
        element: <LandingLayout element={<PrivacyPolicy />} />,
      },
      {
        path: "terms-conditions",
        element: <LandingLayout element={<TermsAndConditions />} />,
      },
      {
        path: "contracts",
        element: <LandingLayout element={<Contracts />} />,
      },
      {
        path: "duplicate-lands/:id",
        element: (
          <ProtectedLayout>
            <LandingLayout element={<DuplicateLands />} />
          </ProtectedLayout>
        ),
      },
      {
        path: "dashboard",
        element: <DashbaordLayout />,
        children: [
          { path: "profile", element: <Profile /> },
          {
            path: "purchased-lands",
            element: <PurchasedLands />,
          },
          {
            path: "my-NFTs",
            element: <MyNFTs />,
          },
          {
            path: "my-referrals",
            element: <Referees />,
          },
          {
            path: "my-stakings",
            element: <MyStakings />,
          },
          {
            path: "claimed-history",
            element: <ClaimedHistory />,
          },
        ],
      },
      { path: "signup", element: <PublicRoute element={<Signup />} /> },
      { path: "*", element: <PagesError /> },
    ],
  },
];

export default AppRoute;
