import React from "react";
import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";

const TileInfo = ({ tile_count, purchase_price_token, tilePrice }) => {
  const list = [
    { title: "Selected Tiles", info: tile_count },
    // {
    //   title: "Value",
    //   info: `$ ${fixPrecisionAndFormat(
    //     tile_count * tilePrice,
    //     2
    //   )}`,
    // },
    // {
    //   title: "Current Value per tiles",
    //   info: `$ ${tilePrice}`,
    // },
    // { title: "PME", info: purchase_price_token },
  ];
  return (
    <div className="flex flex-col gap-2 mt-10">
      {list.map((item, index) => (
        <div
          key={index}
          className="p-3 bg-dashboard-frame h-10 flex justify-between items-center rounded-[20px]"
        >
          <div className="text-body-md md:text-label-md text-blackshade-200">
            {item.title}
          </div>
          <div className="text-label-sm md:text-label-lg text-white">
            {item.info}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TileInfo;
