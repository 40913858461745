import { toast } from "react-toastify";
import {
  Cartesian3,
  Color,
  Math as CesiumMath,
  Cartographic,
  PolygonGeometry,
  GeometryInstance,
  PolygonHierarchy,
  ColorGeometryInstanceAttribute,
  Geometry,
  Primitive,
  MaterialAppearance,
  PerInstanceColorAppearance,
} from "cesium";

export let startLatitude = -49.402488;
export let endLatitude = -49.392488;
export let startLongitude = 69.556083;
export let endLongitude = 69.566083;
// let startLatitude = 37.7749;
// let endLatitude = 37.7849;
// let startLongitude = -122.4194;
// let endLongitude = -122.4094;
export const gridCellWidth = 10.0;
export const gridCellHeight = 10.0;
export let latitude = -49.402488; // Example latitude
export const gridCellSizeMeters = 10.0; // Grid cell size in meters
export const gridCellDegrees = metersToDegrees(gridCellSizeMeters, latitude);

export const gridCellWidthDegrees = gridCellDegrees.deltaLon;
export const gridCellHeightDegrees = gridCellDegrees.deltaLat;
export const marginDegrees = 0.01;

// Boundary coordinates
export const taafBoundary = {
  minLat: -49.73535752784765,
  maxLat: -48.65864175350342,
  minLon: 68.64418293720291,
  maxLon: 70.55402577847411,
};

export function metersToDegrees(meters, latitude) {
  const metersPerDegreeLat = 111320; // Approximation of meters per degree of latitude
  const radians = (latitude * Math.PI) / 180;
  const metersPerDegreeLon = metersPerDegreeLat * Math.cos(radians); // Varies with latitude
  const deltaLat = meters / metersPerDegreeLat;
  const deltaLon = meters / metersPerDegreeLon;
  return {
    deltaLat: deltaLat,
    deltaLon: deltaLon,
  };
}

const isEqualWithinTolerance = (a, b) => {
  return Math.abs(a - b) <= TOLERANCE;
};

const findCellIndex = (cells, centerLat, centerLon) => {
  return cells.findIndex(
    (cell) =>
      isEqualWithinTolerance(cell.centerLat, centerLat) &&
      isEqualWithinTolerance(cell.centerLon, centerLon)
  );
};

export function snapToGrid(value, gridSize) {
  return Math.floor(value / gridSize) * gridSize;
}

export function checkCameraHeight(viewer) {
  const cameraHeight = viewer.camera.positionCartographic.height;
  if (cameraHeight > 500) {
    // toast.info("Zoom in to select land", { toastId: "selectLand" });
  }
}

// Function to execute when the globe is fully loaded
export function onGlobeLoaded(viewer) {
  viewer.camera.flyTo({
    destination: Cartesian3.fromDegrees(
      (startLongitude + endLongitude) / 2,
      (startLatitude + endLatitude) / 2,
      20000
    ), // Specify the destination with altitude
    duration: 8.0, // Duration of the flight animation in seconds
    orientation: {
      heading: 0.0,
      pitch: CesiumMath.toRadians(-90.0),
      roll: 0.0,
    },
  });
}

//stop flight
export function stopFlight(viewer) {
  viewer.camera.cancelFlight();
}

// create selected cells with polygon

export const createCellPolygon = ({
  coordinates,
  color,
  userOwn,
  allTiles,
  disabled,
}) => {
  const { cellMinLon, cellMaxLon, cellMinLat, cellMaxLat } = coordinates;

  // Define the color based on tile type
  const polygonColor = color
    ? color
    : userOwn
    ? Color.fromCssColorString('#FFB70F')
    : allTiles
    ? Color.fromCssColorString('#FFB70F')
    : disabled
    ? Color.BLACK.withAlpha(0.5)
    : Color.BLUE.withAlpha(0.9);

  return new GeometryInstance({
    geometry: new PolygonGeometry({
      polygonHierarchy: new PolygonHierarchy(
        Cartesian3.fromDegreesArray([
          cellMinLon,
          cellMinLat,
          cellMaxLon,
          cellMinLat,
          cellMaxLon,
          cellMaxLat,
          cellMinLon,
          cellMaxLat,
        ])
      ),
      vertexFormat: PerInstanceColorAppearance.VERTEX_FORMAT,
    }),
    attributes: {
      color: ColorGeometryInstanceAttribute.fromColor(polygonColor),
    },
  });
};

const TOLERANCE = 0.00005; // Tolerance level for floating-point comparison

const isAdjacent = (cell1, cell2) => {
  const lonDifference = Math.abs(cell1.centerLon - cell2.centerLon);
  const latDifference = Math.abs(cell1.centerLat - cell2.centerLat);

  // Check if cells are horizontally adjacent (same latitude, longitude difference close to grid cell width)
  const isHorizontallyAdjacent =
    Math.abs(cell1.centerLat - cell2.centerLat) <= TOLERANCE &&
    Math.abs(lonDifference - gridCellWidthDegrees) <= TOLERANCE &&
    lonDifference > TOLERANCE;

  // Check if cells are vertically adjacent (same longitude, latitude difference close to grid cell height)
  const isVerticallyAdjacent =
    Math.abs(cell1.centerLon - cell2.centerLon) <= TOLERANCE &&
    Math.abs(latDifference - gridCellHeightDegrees) <= TOLERANCE &&
    latDifference > TOLERANCE;

  // Return true if cells are adjacent either horizontally or vertically, false otherwise
  return isHorizontallyAdjacent || isVerticallyAdjacent;
};

// Function to generate a unique identifier based on coordinates
export const generateIdentifierFromCoordinates = (
  minLon,
  minLat,
  maxLon,
  maxLat
) => {
  return `${Math.round(minLon * 100000)}-${Math.round(
    minLat * 100000
  )}-${Math.round(maxLon * 100000)}-${Math.round(maxLat * 100000)}`;
};
export const createSelectedCellPolygon = ({
  viewer,
  col,
  row,
  coordinates,
  disabled,
  longitude,
  latitude,
  userOwn,
  allTiles,
  color,
}) => {
  const { cellMinLon, cellMaxLon, cellMinLat, cellMaxLat } = coordinates;
  const polygon = viewer.entities.add({
    polygon: {
      hierarchy: Cartesian3.fromDegreesArray([
        cellMinLon,
        cellMinLat,
        cellMaxLon,
        cellMinLat,
        cellMaxLon,
        cellMaxLat,
        cellMinLon,
        cellMaxLat,
      ]),
      material: color
        ? color
        : userOwn
        ? Color.PURPLE.withAlpha(0.4)
        : allTiles
        ? Color.fromCssColorString('#FFB70F')
        : disabled
        ? Color.BLACK.withAlpha(0.5)
        : Color.fromCssColorString('#338EF7').withAlpha(0.7),
    },
  });

  const centerLon = (cellMinLon + cellMaxLon) / 2;
  const centerLat = (cellMinLat + cellMaxLat) / 2;

  return {
    row,
    col,
    centerLon,
    centerLat,
    coordinates,
    polygon,
    disabled: disabled ?? false,
    longitude,
    latitude,
  };
};
export function fillCellHandler({
  position,
  viewer,
  selectedCells,
  setSelectedCells,
  userOwnedTiles,
  setSelectedCellsHistory,
  setClickCount,
  clickCount,
}) {
  if (!viewer.camera) {
    return;
  }
  const cameraHeight = viewer.camera.positionCartographic.height;

  if (cameraHeight < 500) {
    const cartesian = viewer.camera.pickEllipsoid(
      position,
      viewer.scene.globe.ellipsoid
    );
    if (cartesian) {
      const cartographic = Cartographic.fromCartesian(cartesian);
      const longitude = CesiumMath.toDegrees(cartographic.longitude);
      const latitude = CesiumMath.toDegrees(cartographic.latitude);

      const { minLat, minLon, maxLat, maxLon } = taafBoundary;

      const cameraExtent = viewer.camera.computeViewRectangle();

      if (!cameraExtent) {
        return;
      }
      startLongitude = CesiumMath.toDegrees(cameraExtent.west) - marginDegrees;
      endLongitude = CesiumMath.toDegrees(cameraExtent.east) + marginDegrees;
      startLatitude = CesiumMath.toDegrees(cameraExtent.south) - marginDegrees;
      endLatitude = CesiumMath.toDegrees(cameraExtent.north) + marginDegrees;

      // Snap start and end to the grid
      startLongitude = snapToGrid(startLongitude, gridCellWidthDegrees);
      endLongitude =
        snapToGrid(endLongitude, gridCellWidthDegrees) + gridCellWidthDegrees;
      startLatitude = snapToGrid(startLatitude, gridCellHeightDegrees);
      endLatitude =
        snapToGrid(endLatitude, gridCellHeightDegrees) + gridCellHeightDegrees;

      // Adjust the start and end coordinates to be within the region boundaries
      startLongitude = Math.max(startLongitude, minLon);
      endLongitude = Math.min(endLongitude, maxLon);
      startLatitude = Math.max(startLatitude, minLat);
      endLatitude = Math.min(endLatitude, maxLat);

      const col = Math.floor(
        (longitude - startLongitude) / gridCellWidthDegrees
      );
      const row = Math.floor(
        (latitude - startLatitude) / gridCellHeightDegrees
      );

      const cellMinLon = startLongitude + col * gridCellWidthDegrees;
      const cellMaxLon = cellMinLon + gridCellWidthDegrees;
      const cellMinLat = startLatitude + row * gridCellHeightDegrees;
      const cellMaxLat = cellMinLat + gridCellHeightDegrees;

      const coordinates = {
        cellMinLon,
        cellMaxLon,
        cellMinLat,
        cellMaxLat,
      };

      const centerLon = (cellMinLon + cellMaxLon) / 2;
      const centerLat = (cellMinLat + cellMaxLat) / 2;
      // const existingIndex = selectedCells.findIndex((cell) =>
      //   checkOverlap({ cell, coordinates })
      // );
      // const existingIndex = selectedCells.findIndex(
      //   (cell) => cell.centerLon === centerLon && cell.centerLat === centerLat
      // );
      const userOwnedTilesIndex = findCellIndex(
        userOwnedTiles,
        centerLat,
        centerLon
      );
      if (userOwnedTilesIndex !== -1) {
        return;
      }
      const index = findCellIndex(selectedCells, centerLat, centerLon);
      // Unique identifier for the cell
      const cellIdentifier = generateIdentifierFromCoordinates(
        cellMinLon,
        cellMinLat,
        cellMaxLon,
        cellMaxLat
      ); // Check if the selected cell is within the boundary

      if (
        centerLat >= minLat &&
        centerLat <= maxLat &&
        centerLon >= minLon &&
        centerLon <= maxLon
      ) {
        if (index !== -1) {
          // const cellToDeselect = selectedCells[index];
          // if (cellToDeselect.disabled) {
          //   console.log("Cannot deselect a disabled cell.");
          //   return;
          // }
          // // // Count adjacent cells that are selected
          // const adjacentCount = selectedCells.filter((cell, i) => {
          //   if (i === index) return false; // Skip the cell itself
          //   return areAdjacent(cellToDeselect, cell);
          // }).length;
          // if (adjacentCount === 4) {
          //   toast.error("Cannot deselect this cell", {
          //     toastId: "deselectBorderedCell",
          //   });
          //   return; // Exit the function without deselecting
          // }
          // Deselect the cell
          // viewer.entities.remove(selectedCells[index].polygon);
          // const newSelectedCells = selectedCells.filter((_, i) => i !== index);
          // setSelectedCells(newSelectedCells);
        } else {
          const clickedCell = { centerLon, centerLat };

          const isAnyCellAdjacent = selectedCells.some((selectedCell) =>
            isAdjacent(clickedCell, selectedCell)
          );

          setClickCount((prevCount) => prevCount + 1);
          const currentClickCount = clickCount + 1;
          const isAdjacencyCheckRequired = currentClickCount % 2 === 1; // Third, fifth, etc., clicks

          if (selectedCells.length === 0) {
            setClickCount(1);
          }
          if (currentClickCount === 1) {
            const newCell = {
              ...createSelectedCellPolygon({
                viewer,
                col,
                row,
                coordinates,
                longitude,
                latitude,
              }),
              identifier: cellIdentifier,
            };

            // Select the first cell
            setSelectedCells([newCell]);
            // Store the Last selected cell
            setSelectedCellsHistory((prevHistory) => [
              ...prevHistory,
              [newCell],
            ]);
          } else if (currentClickCount === 2) {
            // Select the second cell and create a rectangle

            const [firstCell] = selectedCells;
            const firstCellCol = Math.floor(
              (firstCell.longitude - startLongitude) / gridCellWidthDegrees
            );
            const firstCellRow = Math.floor(
              (firstCell.latitude - startLatitude) / gridCellHeightDegrees
            );
            const minCol = Math.min(firstCellCol, col);
            const maxCol = Math.max(firstCellCol, col);
            const minRow = Math.min(firstCellRow, row);
            const maxRow = Math.max(firstCellRow, row);

            const cellsToSelect = new Set(
              selectedCells.map((cell) => cell.identifier)
            );
            const userOwnedTileIdentifiers = new Set(
              userOwnedTiles.map((tile) => tile.identifier)
            );
            const lastCells = [];
            // Loop through the bounding box area
            for (let c = minCol; c <= maxCol; c++) {
              for (let r = minRow; r <= maxRow; r++) {
                const cellMinLon = startLongitude + c * gridCellWidthDegrees;
                const cellMaxLon = cellMinLon + gridCellWidthDegrees;
                const cellMinLat = startLatitude + r * gridCellHeightDegrees;
                const cellMaxLat = cellMinLat + gridCellHeightDegrees;

                const cellCoordinates = {
                  cellMinLon,
                  cellMaxLon,
                  cellMinLat,
                  cellMaxLat,
                };

                const cellIdentifier = generateIdentifierFromCoordinates(
                  cellMinLon,
                  cellMinLat,
                  cellMaxLon,
                  cellMaxLat
                );
                // Check if the cell identifier is in the set of user-owned tile identifiers
                if (userOwnedTileIdentifiers.has(cellIdentifier)) {
                  // Skip this cell if it's owned by the user
                  continue;
                }
                if (!cellsToSelect.has(cellIdentifier)) {
                  const cellPolygon = {
                    ...createSelectedCellPolygon({
                      viewer,
                      col: c,
                      row: r,
                      coordinates: cellCoordinates,
                      longitude,
                      latitude,
                    }),
                    identifier: cellIdentifier,
                  };

                  cellsToSelect.add(cellIdentifier);
                  setSelectedCells((prevCells) => [...prevCells, cellPolygon]);
                  lastCells.push(cellPolygon);
                }
              }
            }
            setSelectedCellsHistory((prevHistory) => [
              ...prevHistory,
              [...lastCells],
            ]);
          } else {
            if (isAdjacencyCheckRequired) {
              if (isAnyCellAdjacent) {
                const newCell = {
                  ...createSelectedCellPolygon({
                    viewer,
                    col,
                    row,
                    coordinates,
                    longitude,
                    latitude,
                  }),
                  identifier: cellIdentifier,
                };
                setSelectedCells((prevCells) => [...prevCells, newCell]);

                setSelectedCellsHistory((prevHistory) => [
                  ...prevHistory,
                  [newCell],
                ]);
              } else {
                setClickCount((prevCount) => prevCount - 1);
                toast.error(
                  "Selected cell must share a mutual border with existing cells.",
                  { toastId: "selectMutualBorder" }
                );
              }
            } else {
              const firstCell = selectedCells[selectedCells.length - 1];

              // const [firstCell] = selectedCells;

              const firstCellCol = Math.floor(
                (firstCell.longitude - startLongitude) / gridCellWidthDegrees
              );
              const firstCellRow = Math.floor(
                (firstCell.latitude - startLatitude) / gridCellHeightDegrees
              );
              const minCol = Math.min(firstCellCol, col);
              const maxCol = Math.max(firstCellCol, col);
              const minRow = Math.min(firstCellRow, row);
              const maxRow = Math.max(firstCellRow, row);

              const cellsToSelect = new Set(
                selectedCells.map((cell) => cell.identifier)
              );
              const userOwnedTileIdentifiers = new Set(
                userOwnedTiles.map((tile) => tile.identifier)
              );
              // Loop through the bounding box area
              const lastCells = [];
              for (let c = minCol; c <= maxCol; c++) {
                for (let r = minRow; r <= maxRow; r++) {
                  const cellMinLon = startLongitude + c * gridCellWidthDegrees;
                  const cellMaxLon = cellMinLon + gridCellWidthDegrees;
                  const cellMinLat = startLatitude + r * gridCellHeightDegrees;
                  const cellMaxLat = cellMinLat + gridCellHeightDegrees;

                  const cellCoordinates = {
                    cellMinLon,
                    cellMaxLon,
                    cellMinLat,
                    cellMaxLat,
                  };

                  const cellIdentifier = generateIdentifierFromCoordinates(
                    cellMinLon,
                    cellMinLat,
                    cellMaxLon,
                    cellMaxLat
                  );
                  // Check if the cell identifier is in the set of user-owned tile identifiers
                  if (userOwnedTileIdentifiers.has(cellIdentifier)) {
                    // Skip this cell if it's owned by the user
                    continue;
                  }
                  if (!cellsToSelect.has(cellIdentifier)) {
                    const cellPolygon = {
                      ...createSelectedCellPolygon({
                        viewer,
                        col: c,
                        row: r,
                        coordinates: cellCoordinates,
                        longitude,
                        latitude,
                      }),
                      identifier: cellIdentifier,
                    };

                    cellsToSelect.add(cellIdentifier);
                    setSelectedCells((prevCells) => [
                      ...prevCells,
                      cellPolygon,
                    ]);
                    lastCells.push(cellPolygon);
                  }
                }
              }
              setSelectedCellsHistory((prevHistory) => [
                ...prevHistory,
                [...lastCells],
              ]);
            }
          }
        }
      }
    }
  } else {
    // viewer.entities.removeAll();
  }
  // console.log("clicked", clickCount);
}

export function updateGrid({
  viewer,
  selectedCells,
  setSelectedCells,
  userTiles,
  allTiles,
  setUserOwnedTiles,
}) {
  // Remove existing polylines
  viewer.entities.values
    .filter((entity) => entity.polyline)
    .forEach((entity) => viewer.entities.remove(entity));
  // removeUnselectedPolygons(viewer, selectedCells);

  const cameraHeight = viewer.camera.positionCartographic.height;

  const cameraExtent = viewer.camera.computeViewRectangle();
  if (!cameraExtent || cameraHeight > 500) {
    return;
  }

  let startLongitude = CesiumMath.toDegrees(cameraExtent.west) - marginDegrees;
  let endLongitude = CesiumMath.toDegrees(cameraExtent.east) + marginDegrees;
  let startLatitude = CesiumMath.toDegrees(cameraExtent.south) - marginDegrees;
  let endLatitude = CesiumMath.toDegrees(cameraExtent.north) + marginDegrees;

  const { minLat, minLon, maxLat, maxLon } = taafBoundary;

  startLongitude = Math.max(startLongitude, minLon);
  endLongitude = Math.min(endLongitude, maxLon);
  startLatitude = Math.max(startLatitude, minLat);
  endLatitude = Math.min(endLatitude, maxLat);

  // Snap start and end to the grid
  startLongitude = snapToGrid(startLongitude, gridCellWidthDegrees);
  endLongitude =
    snapToGrid(endLongitude, gridCellWidthDegrees) + gridCellWidthDegrees;
  startLatitude = snapToGrid(startLatitude, gridCellHeightDegrees);
  endLatitude =
    snapToGrid(endLatitude, gridCellHeightDegrees) + gridCellHeightDegrees;

  const gridEntities = [];

  for (
    let lon = startLongitude;
    lon <= endLongitude;
    lon += gridCellWidthDegrees
  ) {
    gridEntities.push({
      polyline: {
        positions: Cartesian3.fromDegreesArray([
          lon,
          startLatitude,
          lon,
          endLatitude,
        ]),
        width: 1.0,
        material: Color.WHITE.withAlpha(0.2),
      },
    });
  }

  for (
    let lat = startLatitude;
    lat <= endLatitude;
    lat += gridCellHeightDegrees
  ) {
    gridEntities.push({
      polyline: {
        positions: Cartesian3.fromDegreesArray([
          startLongitude,
          lat,
          endLongitude,
          lat,
        ]),
        width: 1.0,
        material: Color.WHITE.withAlpha(0.2),
      },
    });
  }

  gridEntities.forEach((entity) => viewer.entities.add(entity));
}

let previousPrimitive = null;

export function formatCellArray({
  tilesData,
  viewer,
  setUserOwnedTiles,
  setIsFieldTiledLoaded,
}) {
  setIsFieldTiledLoaded(false);

  const { userTiles, allTiles } = tilesData;

  if (!viewer.camera) {
    return;
  }

  // Remove the previous primitive from the scene
  if (previousPrimitive && !previousPrimitive.isDestroyed()) {
    viewer.scene.primitives.remove(previousPrimitive);
    // previousPrimitive.destroy(); // Ensure it is properly destroyed
    previousPrimitive = null; // Clear the reference after removal
  }

  const cameraExtent = viewer.camera.computeViewRectangle();

  if (!cameraExtent) {
    return;
  }

  let startLongitude = CesiumMath.toDegrees(cameraExtent.west) - marginDegrees;
  let startLatitude = CesiumMath.toDegrees(cameraExtent.south) - marginDegrees;
  const { minLat, minLon } = taafBoundary;

  startLongitude = Math.max(startLongitude, minLon);
  startLatitude = Math.max(startLatitude, minLat);

  startLongitude = snapToGrid(startLongitude, gridCellWidthDegrees);
  startLatitude = snapToGrid(startLatitude, gridCellHeightDegrees);

  const seenIdentifiers = new Set();

  const processTiles = (tiles, type) => {
    return tiles
      .map(({ latitude, longitude, is_staked, is_nft }) => {
        const position = Cartesian3.fromDegrees(longitude, latitude);
        const cartographic = Cartographic.fromCartesian(position);
        const col = Math.floor(
          (longitude - startLongitude) / gridCellWidthDegrees
        );
        const row = Math.floor(
          (latitude - startLatitude) / gridCellHeightDegrees
        );
        const convertedLongitude = CesiumMath.toDegrees(cartographic.longitude);
        const convertedLatitude = CesiumMath.toDegrees(cartographic.latitude);

        const cellMinLon = startLongitude + col * gridCellWidthDegrees;
        const cellMaxLon = cellMinLon + gridCellWidthDegrees;
        const cellMinLat = startLatitude + row * gridCellHeightDegrees;
        const cellMaxLat = cellMinLat + gridCellHeightDegrees;

        const coordinates = {
          cellMinLon,
          cellMaxLon,
          cellMinLat,
          cellMaxLat,
        };

        const centerLon = (cellMinLon + cellMaxLon) / 2;
        const centerLat = (cellMinLat + cellMaxLat) / 2;

        const cellIdentifier = generateIdentifierFromCoordinates(
          cellMinLon,
          cellMinLat,
          cellMaxLon,
          cellMaxLat
        );

        // Avoid duplicate cells
        if (seenIdentifiers.has(cellIdentifier)) {
          return null;
        }
        seenIdentifiers.add(cellIdentifier);
        return {
          centerLat,
          centerLon,
          col,
          coordinates,
          disabled: true,
          identifier: cellIdentifier,
          latitude: convertedLatitude,
          longitude: convertedLongitude,
          row,
          color: is_staked
            ? Color.fromCssColorString("#17C964").withAlpha(0.7)
            : is_nft
            ? Color.fromCssColorString("#E7EBEE").withAlpha(0.7)
            : type === "allTiles"
            ? Color.fromCssColorString("#F35B1A").withAlpha(0.7)
            : type === "userOwn"
            ? Color.fromCssColorString("#FFB70F").withAlpha(0.7)
            : "",
        };
      })
      .filter(Boolean);
  };

  const userOwnedInstances = userTiles
    ? processTiles(userTiles, "userOwn")
    : [];
  const allTileInstances = allTiles ? processTiles(allTiles, "allTiles") : [];
  const allInstances = [...userOwnedInstances, ...allTileInstances];

  const updateTiles = allInstances.map((tile) => ({
    ...tile,
    polygon: createCellPolygon({
      ...tile,
      viewer,
    }),
  }));

  setUserOwnedTiles([...updateTiles]);

  if (updateTiles.length > 0) {
    const newPrimitive = new Primitive({
      geometryInstances: updateTiles.map((tile) => tile.polygon),
      appearance: new PerInstanceColorAppearance(),
      asynchronous: false,
    });

    const addPrimitivePromise = new Promise((resolve) => {
      viewer.scene.primitives.add(newPrimitive);

      // Check if the primitive is added
      const checkPrimitiveAdded = () => {
        if (viewer.scene.primitives.contains(newPrimitive)) {
          resolve();
        } else {
          requestAnimationFrame(checkPrimitiveAdded);
        }
      };

      checkPrimitiveAdded();
    });

    addPrimitivePromise
      .then(() => {
        if (!viewer.isDestroyed()) {
          previousPrimitive = newPrimitive;
          setIsFieldTiledLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Failed to add primitive:", error);
        if (newPrimitive && !newPrimitive.isDestroyed()) {
          // newPrimitive.destroy();
        }
      });
  } else {
    setIsFieldTiledLoaded(true); // Set to true if no tiles to load
  }
}

export function removeSelectedPolygons(viewer, selectedCells) {
  selectedCells.forEach((cell) => {
    if (cell.polygon) {
      viewer.entities.remove(cell.polygon);
    }
  });
}
export function removeUnselectedPolygons(viewer, selectedCells) {
  // Create a Set of selected cell polygons for quick lookup
  const selectedPolygons = new Set(selectedCells.map((cell) => cell.polygon));
  // Iterate over all entities in the viewer
  viewer.entities.values.forEach((entity) => {
    // Check if the entity is a polygon and not in the selected polygons set
    if (entity.polygon && !selectedPolygons.has(entity)) {
      viewer.entities.remove(entity);
    }
  });
}

export function updateBounds(cameraExtent) {
  const marginDegrees = 0.003;
  // Extract and convert camera extent
  let startLongitude = CesiumMath.toDegrees(cameraExtent.west) - marginDegrees;
  let endLongitude = CesiumMath.toDegrees(cameraExtent.east) + marginDegrees;
  let startLatitude = CesiumMath.toDegrees(cameraExtent.south) - marginDegrees;
  let endLatitude = CesiumMath.toDegrees(cameraExtent.north) + marginDegrees;

  // Define boundary limits
  const { minLat, minLon, maxLat, maxLon } = taafBoundary;

  // Clamp to boundary limits
  startLongitude = Math.max(startLongitude, minLon);
  endLongitude = Math.min(endLongitude, maxLon);
  startLatitude = Math.max(startLatitude, minLat);
  endLatitude = Math.min(endLatitude, maxLat);

  // Snap to grid and adjust for grid width and height
  startLongitude = snapToGrid(startLongitude, gridCellWidthDegrees);
  endLongitude =
    snapToGrid(endLongitude, gridCellWidthDegrees) + gridCellWidthDegrees;
  startLatitude = snapToGrid(startLatitude, gridCellHeightDegrees);
  endLatitude =
    snapToGrid(endLatitude, gridCellHeightDegrees) + gridCellHeightDegrees;
  // Return updated bounds
  return {
    s: startLatitude.toFixed(6), // Optionally fix decimal places for consistency
    n: endLatitude.toFixed(6),
    e: endLongitude.toFixed(6),
    w: startLongitude.toFixed(6),
  };
}
