import React from "react";
import { Range, getTrackBackground } from "react-range";

interface RangeSliderProps {
  values: number[];
  min: number;
  max: number;
  step: number;
  tooltipMessage: string;
  onChange: (values: number[]) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  values,
  min,
  max,
  step,
  tooltipMessage,
  onChange,
}) => {
  return (
    <div className="flex justify-center flex-wrap mx-7">
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#006FEE", "#7828C8"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              background:
                "conic-gradient(#7828C8 0deg 180deg, #006FEE 180deg 360deg)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              filter: "drop-shadow(0px 0px 5.5px rgba(0, 0, 0, 0.41))",
              position: "relative",
            }}
          >
            {isDragged && (
              <div className="absolute -top-9 left-1/2 bg-blackshade-700 -translate-x-1/2 rounded-md p-1 text-white text-body-md text-center whitespace-nowrap">
                {tooltipMessage}
                <div
                  style={{
                    borderLeft: "5px solid transparent",
                    borderRight: "5px solid transparent",
                    borderTop: "5px solid #2E2E2E",
                  }}
                  className="absolute left-1/2 border-t-blackshade-700 -translate-x-1/2 
                  w-0 h-0 -bottom-1"
                />
              </div>
            )}
            <div className="w-[10px] h-[10px] bg-white rounded-full" />
          </div>
        )}
      />
    </div>
  );
};

export default RangeSlider;
