import React from "react";
import { LandStateProvider } from "./context";
import Wrapper from "./Wrapper";

const BuyLandModalBox = ({ closeModalHandler, contractData }) => {
  return (
    <LandStateProvider>
      <Wrapper
        closeModalHandler={closeModalHandler}
        contractData={contractData}
      />
    </LandStateProvider>
  );
};

export default BuyLandModalBox;
