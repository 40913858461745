import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "@/utils/axiosInstance";
import laptop from "@/scss/images/laptop.png";
import Input from "@/components/Common/Input";
import Button from "@/components/Common/Button";
import PCOText from "@/scss/images/PICOLAND-text.png";
import { useToast } from "@/components/Common/Toast/utils";

const Subscription: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ email?: string }>({});
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { addToast } = useToast();

  const validate = () => {
    let errors: { email?: string } = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      try {
        const token = await recaptchaRef.current?.executeAsync();
        recaptchaRef.current?.reset(); // Reset reCAPTCHA for next use
        if (!token)
          throw new Error(
            "Failed to obtain reCAPTCHA token. Please try again."
          );

        const response = await api.post("/subscription_email/", { email });
        if (response.data.status === "Success") {
          addToast(
            "success",
            "Subscription successful! Thank you for subscribing."
          );
          setEmail("");
        } else {
          addToast("danger", "Subscription failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during submission:", error);
        addToast(
          "danger",
          (error as any).response?.data?.message ||
            "An error occurred. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="container">
      <form
        action="#"
        onSubmit={handleSubmit}
        className="mt-[10rem] mb-[5rem] desktop:mt-[20rem] desktop:mb-[8rem]"
      >
        <img
          data-aos="fade-up"
          data-aos-duration="700"
          src={PCOText}
          alt="pco"
          className="w-full"
        />
        <div data-aos="fade-up" data-aos-duration="1000">
          <div className="gradient-border-box rounded-3xl pb-4 px-4 pt-[154px] desktop:p-8 w-full mt-10 desktop:-mt-14 text-center desktop:text-left">
            <img
              src={laptop}
              alt="laptop"
              className="absolute left-1/2 -translate-x-1/2 desktop:right-4 desktop:left-auto -top-[7.8rem] desktop:top-auto desktop:translate-x-0 desktop:-bottom-[5rem] z-10 h-[264px] desktop:h-[540px]"
            />
            <h3 className="text-gradiant text-headline-md desktop:text-display-sm">
              Join Now For Early Access
            </h3>
            <div className="text-blackshade-200 text-label-lg desktop:text-title-lg mt-2 mb-8 desktop:max-w-[500px]">
              Discover PCOLand, the ultimate platform for trading metaverse
              properties.
            </div>
            <div className="flex gap-6 items-center flex-wrap">
              <div className="w-full desktop:w-max">
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  required
                  label="Email Address"
                  inputClassName={`w-full desktop:w-[360px]`}
                  isError={!!errors.email}
                  msg={
                    errors.email && (
                      <div className="text-red-600">{errors.email}</div>
                    )
                  }
                />
              </div>
              <Button
                model="main"
                size="lg"
                className="w-full desktop:w-max"
                isLoading={isLoading}
                type="submit"
              >
                Subscribe
              </Button>
            </div>
          </div>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        />
      </form>
    </div>
  );
};
export default Subscription;
