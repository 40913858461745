import { CheckedRadio, UncheckedRadio } from "@/components/Icons";
import React from "react";

interface RadioProps {
  value: string;
  name: string;
  label: string;
  id: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const Radio: React.FC<RadioProps> = ({
  value,
  name,
  label,
  id,
  checked,
  onChange,
}) => {
  return (
    <div className="flex items-center mb-4">
      <div onClick={() => onChange(value)} className="cursor-pointer">
        {checked ? <CheckedRadio /> : <UncheckedRadio />}
      </div>

      <input
        id={id}
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={() => onChange(value)}
        className="hidden"
      />
      <label
        htmlFor={id}
        className="cursor-pointer ml-2 text-label-lg text-white"
      >
        {label}
      </label>
    </div>
  );
};

export default Radio;
