import * as React from "react";
import type { SVGProps } from "react";
const SvgUncheckedRadio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#7D7D7D"
      strokeWidth={1.5}
      d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z"
    />
  </svg>
);
export default SvgUncheckedRadio;
