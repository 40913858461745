import { useEffect, useContext, useState } from "react";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useReadContract,
} from "wagmi";
import { api } from "@/utils/axiosInstance";
import { convertTilesToString } from "@/utils/landHelpers";
import { useLandState } from "../context";
import { useToast } from "@/components/Common/Toast/utils";
import {
  landAbi,
  approveAbi,
  pmeApproveAbi,
  maticTokenAddress,
} from "@/utils/contractConstants";
import { parseEther } from "ethers";
import { MapContext } from "../../context.jsx";
import { useQueryClient } from "@tanstack/react-query";

export const useLandActions = ({
  closeModalHandler,
  contractData,
  address,
}) => {
  const [approvalData, setApprovalData] = useState(null);
  const { addToast } = useToast();
  const { tileNumber, emptySelectedCells, selectedCells } =
    useContext(MapContext);
  const {
    isLoading,
    setIsLoading,
    setShowStepper,
    tokenPercentageValue,
    selectedTokenValue,
    setFetchLandError,
    setFetchLandData,
    isFetchingLand,
    setIsFetchingLand,
    fetchLandError,
    fetchLandData,
  } = useLandState();
  const approve = useWriteContract();
  const PMGApprove = useWriteContract();
  const queryClient = useQueryClient();

  const purchaseLand = useWriteContract();
  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
  });
  const PMGTransactionReceipt = useWaitForTransactionReceipt({
    hash: PMGApprove.data,
  });
  const userBalance = useBalance({ address });

  const pmeBalance = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  });
  const pmgBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
  });
  const maticBalance = useBalance({ address, token: maticTokenAddress });
  const allowance = useReadContract({
    address: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_CONTRACT_ADDRESS],
    watch: true,
  });
  useEffect(() => {
    allowance.refetch();
    queryClient.invalidateQueries({ queryKey: userBalance.queryKey });
  }, []);
  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      // PMGApprove;
      queryClient.invalidateQueries({ queryKey: allowance.queryKey });
    }
  }, [transactionReceipt.isSuccess]);
  const PMGApproveHandler = () => {
    const purchasePriceTokenInWei = parseEther(
      approvalData.approve_token.toString()
    );
    PMGApprove.writeContractAsync({
      address: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
      abi: approveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
        purchasePriceTokenInWei,
      ],
    });
  };
  const approveHandler = async (data) => {
    const purchasePriceTokenInWei = parseEther(
      approvalData
        ? approvalData.approve_token.toString()
        : data?.approve_token.toString()
    );
    approve.writeContractAsync({
      address: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
      abi: pmeApproveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
        purchasePriceTokenInWei,
      ],
    });
  };
  const contractHandler = async () => {
    setIsLoading(true);
    api
      .post("/get_approval_amount/", {
        token_type: "PME",
        token_type_2: "PMG",
        token_type_2_percentage:
          selectedTokenValue === "PMG" ? tokenPercentageValue : 0,
        use_combined_tokens: selectedTokenValue === "PMG" ? true : false,
        tile_count: contractData.tile_count,
      })
      .then((res) => {
        setApprovalData(res.data.data);
        approveHandler(res.data.data);
      });
  };
  useEffect(() => {
    if (
      approvalData?.use_combined_tokens &&
      transactionReceipt.isSuccess &&
      !PMGApprove.data
    ) {
      PMGApproveHandler();
    }
  }, [approvalData, transactionReceipt.isSuccess, PMGApprove.data]);
  const fetchBuyLandData = async () => {
    setIsFetchingLand(true);
    try {
      const res = await api.post("/buy_land/", {
        tile_count: tileNumber,
        tile_data: convertTilesToString(selectedCells),
        token_type: "PME",
        is_forsale: false,
        user_address: address,
      });
      if (res.data.status === "Error") {
        addToast("danger", res.data.message);
        setFetchLandError(true);
      } else if (res.data.duplicated) {
        addToast("danger", "This land has already been bought.");
      } else if (res.data.reserved) {
        addToast("danger", "This land has already been reserved.");
      } else {
        setFetchLandData(res.data.data);
        purchaseLandHandler(res.data.data);
      }
    } catch (error) {
      console.log({ error });
      addToast("danger", error.message ?? error.response?.data.message);
      setFetchLandError(true);
      if (error.response?.status === 423) {
        emptySelectedCells();
        closeModalHandler();
      }
    } finally {
      setIsFetchingLand(false);
    }
  };

  const purchaseLandHandler = (data) => {
    purchaseLand.writeContract({
      address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
      abi: landAbi,
      functionName: "purchaseLand",
      args: [
        data.tilecount,
        "PME",
        "PMG",
        selectedTokenValue === "PMG" ? tokenPercentageValue : 0,
        data.tile_data,
        data.is_forsale,
      ],
    });
  };
  const buyLandHandler = async () => {
    const pmg =
      (tokenPercentageValue / 100) * contractData.purchase_price_token;

    const pme = contractData.purchase_price_token - pmg;

    try {
      if (Number(pmeBalance?.data?.formatted) < pme) {
        addToast(
          "danger",
          "Your PME balance is not sufficient to complete the purchase."
        );
      } else if (
        Number(pmgBalance?.data?.formatted) < pmg &&
        selectedTokenValue === "PMG"
      ) {
        addToast(
          "danger",
          "Your PMG balance is not sufficient to complete the purchase."
        );
        // closeModalHandler();
      } else if (Number(maticBalance?.data?.formatted) < 0.1) {
        addToast(
          "danger",
          "Your Matic balance is not sufficient to complete the purchase."
        );
        closeModalHandler();
      } else {
        setIsLoading(true);
        setShowStepper(true);
        await contractHandler();
      }
    } catch (err) {
      console.error("Error in buyLandHandler:", err);
      // setIsLoading(false);
    }
  };

  const isDisabled =
    isLoading ||
    purchaseLand.isLoading ||
    approve.isLoading ||
    approve.isPending ||
    PMGApprove.isLoading ||
    PMGApprove.isPending ||
    purchaseLand.isPending ||
    transactionReceipt.isFetching ||
    userBalance.isFetching ||
    isFetchingLand;

  useEffect(() => {
    // Handle transaction success, errors, or status changes
  }, [transactionReceipt, purchaseLand]);
  useEffect(() => {
    if (
      transactionReceipt.isSuccess &&
      !purchaseLand.isSuccess &&
      PMGTransactionReceipt.isSuccess
    ) {
      fetchBuyLandData();
    }
  }, [transactionReceipt.isSuccess, PMGTransactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);
  const totalStatus = (() => {
    if (purchaseLand.isPending) return "in-progress";
    if (purchaseLand.isSuccess) return "active";
    if (purchaseLand.isError) return "failed";
    if (
      approve.isPending ||
      transactionReceipt.isFetching ||
      PMGApprove.isFetching ||
      PMGApprove.isPending
    )
      return "in-progress";
    if (
      approve.isError ||
      transactionReceipt.isError ||
      PMGApprove.isError ||
      fetchLandError
    )
      return "failed";
    if (transactionReceipt.isSuccess && PMGTransactionReceipt.isSuccess)
      return "active";

    return "in-active";
  })();

  const totalRetryHandler = purchaseLand.isError
    ? () => purchaseLandHandler(fetchLandData)
    : approve.isError
    ? approveHandler
    : PMGApprove.isError
    ? PMGApproveHandler
    : transactionReceipt.isError
    ? transactionReceipt.refetch
    : PMGTransactionReceipt.isError
    ? PMGTransactionReceipt.refetch
    : !fetchLandData && !isFetchingLand && fetchLandError
    ? fetchBuyLandData
    : null;
  const totalStatusTitle = (() => {
    if (purchaseLand.isPending) return "Waiting for Wallet Confirmation";
    if (purchaseLand.isError) return "Failed to Purchase";
    if (purchaseLand.isSuccess) return "Land Purchase Done!";
    if (isFetchingLand) return "Retrieving Land Information";
    if (fetchLandError) return "Failed to Retrieve Land Information";

    if (approve.isPending || PMGApprove.isPending)
      return "Waiting for Wallet Approval";
    if (approve.isError || PMGApprove.isError) return "Failed to Approve";
    if (transactionReceipt.isFetching || PMGTransactionReceipt.isFetching)
      return "Waiting for Transaction Confirmation";
    if (transactionReceipt.isSuccess || PMGTransactionReceipt.isSuccess)
      return "Approval Completed";

    return "Fetching Approve Data";
  })();

  return {
    approveHandler,
    fetchBuyLandData,
    purchaseLandHandler,
    buyLandHandler,
    isDisabled,
    totalStatus,
    totalStatusTitle,
    totalRetryHandler,
    approve,
    transactionReceipt,
    purchaseLand,
  };
};
