import { useContext } from "react";
import { MapContext } from "../../context.jsx";
import {
  CartLargeMinimalistic,
  DangerCircle,
  LayersMinimalistic,
  Trash,
} from "@/components/Icons";
import Button from "@/components/Common/Button";

const Sidebar = ({ isLoading, buyLandHandler }) => {
  const { tileNumber, emptySelectedCells, landInfo } = useContext(MapContext);
  return (
    <div className={`hidden desktop:block`}>
      <div className="space-y-4 absolute desktop:absolute rounded-[20px] bg-blackshade-900 shadow-frame p-4 left-8 top-10 desktop:w-[380px] z-10">
        <div className="rounded-2xl bg-dashboard-frame p-3">
          <h2 className="text-label-lg desktop:text-subheading-md text-white">
            LAND INFO
          </h2>
        </div>
        <div className="rounded-2xl bg-dashboard-frame p-3 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <DangerCircle className="text-white w-5 h-5" />
            <h2 className="text-label-lg text-blackshade-200">
              You must select {landInfo?.tiles_count} tiles.
            </h2>
          </div>
        </div>
        <div className="rounded-2xl bg-dashboard-frame p-3 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <LayersMinimalistic className="text-gold-500 w-5 h-5" />
            <h2 className="text-label-lg text-blackshade-200">
              Selected TILES
            </h2>
          </div>
          <div className="text-white desktop:text-label-lg">
            <span className="text-gold-500">{tileNumber}</span> TILES
          </div>
        </div>

        <div className="border-t border-blackshade-800 pt-3 mt-4 flex flex-row-reverse desktop:block">
          <Button
            model="main"
            size="md"
            className="w-full"
            onClick={buyLandHandler}
            isLoading={isLoading}
          >
            <CartLargeMinimalistic />
            <span>Buy Now</span>
          </Button>
          <Button
            model="error"
            size="md"
            className="desktop:mt-4 w-full flex items-center"
            onClick={() => emptySelectedCells()}
          >
            <Trash />
            <span>Clear Selecttion</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
