import { Coin, Earth, LayersMinimalistic, Wallet } from "@/components/Icons";
export const stepperList = ({
  approve,
  transactionReceipt,
  approveHandler,
  isFetchingLand,
  fetchLandData,
  purchaseLandHandler,
  purchaseLand,
  fetchLandError,
  fetchBuyLandData,
}) => [
  {
    id: 0,
    title: "Token",
    icon: <Coin />,
    status:
      approve.isPending || approve.isError || approve.isSuccess
        ? "active"
        : "in-progress",
  },
  {
    id: 1,
    title: "wallet approval",
    icon: <Wallet />,
    status:
      approve.isPending || transactionReceipt.isFetching
        ? "in-progress"
        : transactionReceipt.isSuccess
        ? "active"
        : approve.isError || transactionReceipt.isError
        ? "failed"
        : "in-active",
    retryHandler: approve.isError
      ? approveHandler
      : transactionReceipt.isError
      ? transactionReceipt.refetch
      : null,
  },
  {
    id: 2,
    title: "Land Detail",
    icon: <Earth />,
    status: isFetchingLand
      ? "in-progress"
      : fetchLandData
      ? "active"
      : fetchLandError
      ? "failed"
      : "in-active",
    retryHandler:
      !fetchLandData && !isFetchingLand && fetchLandError
        ? fetchBuyLandData
        : null,
  },
  {
    id: 3,
    title: "Purchase",
    icon: <LayersMinimalistic />,
    status: purchaseLand.isPending
      ? "in-progress"
      : purchaseLand.isSuccess
      ? "active"
      : purchaseLand.isError
      ? "failed"
      : "in-active",
    retryHandler: purchaseLand.isError
      ? () => purchaseLandHandler(fetchLandData)
      : null,
  },
];
