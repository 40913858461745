import { useContext, useState, useRef } from "react";
import { useAccount } from "wagmi";
import { useAuth } from "@/context/authProvider.js";
import { api } from "@/utils/axiosInstance.js";
import { convertTilesToString } from "@/utils/landHelpers.js";
import ConnectWalletModal from "@/components/Common/ConnectWalletModal";
import BuyLandModal from "../BuyLandModal";
import Sidebar from "./components/Sidebar.jsx";
import { MapContext } from "../context.jsx";
import { useIsMobile } from "@/hooks/useIsMobile";
import BuyLandMobileNavbar from "../BuyLandMobileNavbar";
import { useToast } from "@/components/Common/Toast/utils";

const BuyLandSidebar = () => {
  const { tileNumber, selectedCells, landInfo } = useContext(MapContext);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showBuyDetailModal, setShowBuyDetailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isConnected, address } = useAccount();
  const { user } = useAuth();
  const isMobile = useIsMobile(1200);
  const { addToast } = useToast();

  const buyLandHandler = () => {
    if (tileNumber !== landInfo.tiles_count) {
      addToast("danger", `You must select ${landInfo.tiles_count} tiles.`);
    } else {
      if (user && isConnected) {
        setShowBuyDetailModal(true);
      } else {
        setShowConnectModal(true);
      }
    }
  };

  return (
    <>
      <Sidebar isLoading={isLoading} buyLandHandler={buyLandHandler} />
      {isMobile ? (
        <BuyLandMobileNavbar
          isLoading={isLoading}
          buyLandHandler={buyLandHandler}
          profileBtnHandler={
            user && isConnected ? null : () => setShowConnectModal(true)
          }
        />
      ) : (
        ""
      )}

      {showConnectModal && (
        <ConnectWalletModal
          closeModalHandler={() => setShowConnectModal(false)}
        />
      )}
      {showBuyDetailModal && (
        <BuyLandModal closeModalHandler={() => setShowBuyDetailModal(false)} />
      )}
    </>
  );
};

export default BuyLandSidebar;
