import { useRef, useEffect, useState } from "react";
import { api } from "@/utils/axiosInstance";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useAccount,
  useReadContract,
  useBalance,
} from "wagmi";
import {
  approveAbi,
  landNFTAbi,
  pmeApproveAbi,
} from "@/utils/contractConstants";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LandTypeSelector from "./LandTypeSelector";
import Map from "./Map";
import Stepper from "@/components/Common/Stepper";
import BaseModal from "@/components/Common/BaseModal";
import { Nft, Refresh, Wallet } from "@/components/Icons";
import Button from "@/components/Common/Button";
import { useToast } from "@/components/Common/Toast/utils";

const Modal = ({ landDetails, closeModalHandler }) => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const viewerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [sid, setSid] = useState();
  const [landTypes, setLandTypes] = useState([]);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedLandType, setSelectedLandType] = useState("");
  const [selectTokenType, setSelectTokenType] = useState("");

  const { address } = useAccount();
  const queryClient = useQueryClient();

  const approve = useWriteContract();
  const landConvertor = useWriteContract();

  const pmeBalance = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  });
  const pmgBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
  });

  const approveHandler = async () => {
    await approve.writeContractAsync({
      address:
        selectTokenType === "PME"
          ? process.env.REACT_APP_PME_CONTRACT_ADDRESS
          // : selectTokenType === "PMB"
          // ? process.env.REACT_APP_PMB_CONTRACT_ADDRESS
          : process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
      abi: approveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
        landDetails.approve_token,
      ],
    });
  };

  const convertLandToNFT = async () => {
    await landConvertor.writeContractAsync({
      address: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
      abi: landNFTAbi,
      functionName: "convertLandToNFT",
      args: [landDetails.land_id, sid, selectTokenType],
    });
  };
  const allowance = useReadContract({
    address: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
    abi: pmeApproveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS],
    watch: true,
  });

  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        addToast(
          "danger",
          "You rejected the spending cap request"
        );
        // closeModalHandler();
      }
    }
  }, [approve.isError]);

  useEffect(() => {
    if (landConvertor.isSuccess) {
      closeModalHandler();
      addToast(
        "success",
        "Your Land Has Been Successfully Converted to an NFT!"
      );

      navigate("/dashboard/my-NFTs");
    }
  }, [landConvertor.isSuccess]);
  // useEffect(() => {
  //   if (landConvertor.error) {
  //     toast.error(
  //       landConvertor.error?.cause?.shortMessage || "Purchase failed."
  //     );
  //     // closeModalHandler();
  //     // setIsLoading(false);
  //   }
  // }, [landConvertor.error]);

  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });

  useEffect(() => {
    allowance.refetch();
    queryClient.invalidateQueries({ queryKey: pmeBalance.queryKey });
    queryClient.invalidateQueries({ queryKey: pmgBalance.queryKey });
  }, []);

  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      convertLandToNFT();
      queryClient.invalidateQueries({ queryKey: allowance.queryKey });
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const captureImage = () => {
    return new Promise((resolve, reject) => {
      if (viewerRef.current) {
        const viewer = viewerRef.current;
        viewer.scene.requestRender();
        const listener = () => {
          viewer.scene.postRender.removeEventListener(listener);
          const canvas = viewer.scene.canvas;
          const image = canvas.toDataURL("image/png");
          if (image) {
            resolve(image);
          } else {
            reject(new Error("Failed to capture image."));
          }
        };
        viewer.scene.postRender.addEventListener(listener);
      } else {
        reject(new Error("Viewer is not available."));
      }
    });
  };
  const getNftData = async () => {
    const picture = await captureImage();
    setIsLoading(true);
    setShowStepper(true);
    await api
      .post("/get_nft/", {
        land_id: landDetails.land_id,
        picture,
        land_type: selectedLandType,
        user_address: address,
      })
      .then((res) => {
        setSid(res.data.sid);
        approveHandler();
      });
    // .finally(() => setIsLoading(false));
  };
  const convertButtonHandler = async () => {
    try {
      if (
        Number(pmgBalance?.data?.value) < landDetails.approve_token &&
        selectTokenType === "PMG"
      ) {
        addToast(
          "danger",
          "Your PMG balance is not sufficient to complete the purchase."
        );
      } else if (
        Number(pmeBalance?.data?.value) < landDetails.approve_token &&
        selectTokenType === "PME"
      ) {
        addToast(
          "danger",
          "Your PME balance is not sufficient to complete the purchase."
        );
      } else {
        getNftData();
      }
    } catch (error) {
      console.error("Error capturing image:", error);
      setSid(null);
    }
  };

  const isBtnDisabled =
    isLoading ||
    !isMapLoaded ||
    landConvertor.isPending ||
    transactionReceipt.isFetching ||
    approve.isPending;

  const stepperList = [
    {
      id: 1,
      title: "NFT Information",
      info:
        isLoading && !sid
          ? "Retrieving NFT Information"
          : sid === null
          ? "Failed to Retrieve NFT Information"
          : sid
          ? "Retrieved NFT Information"
          : "NFT Information",
      status:
        isLoading && !sid
          ? "in-progress"
          : sid
          ? "active"
          : sid === null
          ? "failed"
          : "in-active",
      retryHandler:
        !sid && !isLoading && sid === null ? convertButtonHandler : null,
      icon: <Nft />,
    },
    {
      id: 2,
      title: "wallet approval",

      info: approve.isPending
        ? "Waiting for Wallet Approval"
        : approve.isError
        ? "Failed to Approve"
        : transactionReceipt.isFetching
        ? "Waiting for Transaction Confirmation"
        : transactionReceipt.isSuccess
        ? "Approval Completed"
        : "wallet approval",
      status:
        approve.isPending || transactionReceipt.isFetching
          ? "in-progress"
          : transactionReceipt.isSuccess
          ? "active"
          : approve.isError || transactionReceipt.isError
          ? "failed"
          : "in-active",
      retryHandler: approve.isError
        ? approveHandler
        : transactionReceipt.isError
        ? transactionReceipt.refetch
        : null,
      icon: <Wallet />,
    },

    {
      id: 3,
      title: "Convert",

      info: landConvertor.isPending
        ? "Waiting for Wallet Confirmation To NFT"
        : landConvertor.isError
        ? "Failed to Convert Land to NFT"
        : landConvertor.isSuccess
        ? "Land Converted to NFT"
        : "Convert",
      status: landConvertor.isPending
        ? "in-progress"
        : landConvertor.isSuccess
        ? "active"
        : landConvertor.isError
        ? "failed"
        : "in-active",
      retryHandler: landConvertor.isError ? convertLandToNFT : null,
      icon: <Refresh />,
    },
  ];
  const totalStatusTitle =
    isLoading && !sid
      ? "Retrieving NFT Information"
      : sid === null
      ? "Failed to Retrieve NFT Information"
      : sid
      ? approve.isPending
        ? "Waiting for Wallet Approval"
        : approve.isError
        ? "Failed to Approve"
        : transactionReceipt.isFetching
        ? "Waiting for Transaction Confirmation"
        : landConvertor.isPending
        ? "Waiting for Wallet Confirmation To NFT"
        : landConvertor.isError
        ? "Failed to Convert Land to NFT"
        : landConvertor.isSuccess
        ? "Land Converted to NFT"
        : transactionReceipt.isSuccess
        ? "Approval Completed"
        : "Retrieved NFT Information"
      : "Convert";

  const totalStatus =
    isLoading && !sid
      ? "in-progress"
      : sid === null
      ? "failed"
      : sid
      ? approve.isPending || transactionReceipt.isFetching
        ? "in-progress"
        : landConvertor.isPending // Make sure to check landConvertor before transactionReceipt.isSuccess
        ? "in-progress"
        : landConvertor.isSuccess
        ? "active"
        : landConvertor.isError
        ? "failed"
        : transactionReceipt.isSuccess
        ? "active"
        : approve.isError || transactionReceipt.isError
        ? "failed"
        : "active"
      : "in-active";

  const totalRetryHandler =
    !sid && !isLoading && sid === null
      ? convertButtonHandler
      : approve.isError
      ? approveHandler
      : transactionReceipt.isError
      ? transactionReceipt.refetch
      : landConvertor.isError
      ? convertLandToNFT
      : null;

  return (
    <BaseModal
      title="Convert Your Land To NFT"
      closeModalHandler={closeModalHandler}
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          {totalStatus === "failed" && (
            <Button
              model="ghost"
              size="sm"
              onClick={closeModalHandler}
              className="!w-full desktop:!w-max"
            >
              Cancel
            </Button>
          )}
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            onClick={
              totalStatus === "failed"
                ? totalRetryHandler
                : convertButtonHandler
            }
            disabled={
              (isBtnDisabled ||
                selectedLandType.length === 0 ||
                selectTokenType.length === 0) &&
              totalStatus !== "failed"
            }
            isLoading={isLoading && totalStatus !== "failed"}
          >
            {totalStatus === "failed" ? "Try Again" : "Confirm"}
          </Button>
        </div>
      }
    >
      <Stepper
        list={stepperList}
        showStepper={showStepper}
        totalStatus={totalStatus}
        totalStatusTitle={totalStatusTitle}
      />
      {!showStepper && (
        <>
          {" "}
          <h3 className="text-label-lg mt-10 mb-6">
            Retrieved NFT Information
          </h3>
          <Map
            landDetails={landDetails}
            setIsMapLoaded={setIsMapLoaded}
            viewerRef={viewerRef}
          />
          <LandTypeSelector
            landDetails={landDetails}
            selectedLandType={selectedLandType}
            setSelectedLandType={setSelectedLandType}
            setLandTypes={setLandTypes}
            landTypes={landTypes}
            isBtnDisabled={isBtnDisabled || sid}
          />
          <div className="form-select-wrapper">
            <select
              className="form-select mt-2"
              value={selectTokenType}
              onChange={(e) => {
                setSelectTokenType(e.target.value);
              }}
              disabled={(isBtnDisabled && selectTokenType) || sid}
            >
              <option value={""}>Select Your Token </option>
              {/* <option value={"PMB"}>PMB</option> */}
              <option value={"PME"}>PME</option>
              <option value={"PMG"}>PMG</option>
            </select>
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default Modal;
