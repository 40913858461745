import React, { useState, FormEvent } from "react";
import { maticTokenAddress } from "@/utils/contractConstants";
import { useBalance, useAccount } from "wagmi";
import { useQueryClient } from "@tanstack/react-query";
import { FormProps } from "../types";
import wave from "@/scss/images/signup-wave.png";
import avatar from "@/scss/images/avatar-profile.png";
import Input from "@/components/Common/Input";
import { MEssage, Plate, UserRounded } from "@/components/Icons";
import Button from "@/components/Common/Button";
import CheckBox from "@/components/Common/Checkbox";
import stars from "@/scss/images/stars.png";
import Card from "./Card";
import { useToast } from "@/components/Common/Toast/utils";

const Form: React.FC<FormProps> = ({
  isLoading,
  transactionReceipt,
  checkIsUserDuplicated,
  handleChange,
  formData,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { email, username, referral_id } = formData;
  const { address } = useAccount();
  const maticBalance = useBalance({ address, token: maticTokenAddress });
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const validate = (): { [key: string]: string } => {
    const errors: { [key: string]: string } = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!username) {
      errors.username = "Username is required";
    } else if (username.length < 3) {
      errors.username = "Username must be at least 3 characters";
    }

    if (!termsAccepted) {
      errors.terms = "You must accept the terms and conditions";
    }

    return errors;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries({ queryKey: maticBalance.queryKey });
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      if (Number(maticBalance?.data?.formatted) < 0.1) {
        addToast(
          "danger",
          "Your Matic balance is not sufficient to complete the signup."
        );
      } else {
        checkIsUserDuplicated({ username, email, referral_id });
      }
    }
  };

  return (
    <div className=" pt-20 pb-10">
      {transactionReceipt.isFetching ? (
        <Card />
      ) : (
        <div className="container">
          <div className="bg-dashboard-frame frame-effects rounded-3xl p-8 max-w-[562px] relative z-1 mx-auto px-4 desktop:px-[96px]">
            <form onSubmit={handleSubmit} className="">
              <h1 className="text-center text-display-sm text-gradiant mb-4">
                Sign Up
              </h1>
              <img src={avatar} alt="" className="w-[230px] mx-auto" />
              <div className="flex flex-col gap-7">
                <Input
                  label="Email Address"
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  prefixIcon={<MEssage />}
                  msg={
                    errors.email && (
                      <div className="text-red-600">{errors.email}</div>
                    )
                  }
                  isError={!!errors.email}
                />

                <Input
                  label="Username"
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleChange}
                  prefixIcon={<UserRounded />}
                  msg={
                    errors.username && (
                      <div className="text-red-600">{errors.username}</div>
                    )
                  }
                  isError={!!errors.username}
                />
                <Input
                  label="Refferal Code"
                  type="text"
                  name="referral_id"
                  value={referral_id}
                  onChange={handleChange}
                  prefixIcon={<Plate />}
                />
                <div className="flex items-center relative">
                  <CheckBox
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />

                  <label className="text-label-lg text-white" htmlFor="terms">
                    I accept the{" "}
                    <a
                      href="/terms-conditions"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gold-500"
                    >
                      terms and conditions
                    </a>
                  </label>
                  {errors.terms && (
                    <div className="text-red-600 absolute -bottom-4 left-4">
                      {errors.terms}
                    </div>
                  )}
                </div>
                <Button
                  className="w-full"
                  type="submit"
                  model="main"
                  isLoading={isLoading}
                  size="lg"
                >
                  Sign Up
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      <img src={stars} alt="stars" className="absolute inset-0 -z-20" />
      <img
        src={wave}
        alt=""
        className="absolute bottom-0 right-0 w-full h-[381px] -z-10"
      />
    </div>
  );
};

export default Form;
