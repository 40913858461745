import React from "react";
import { fixPrecisionAndFormat } from "@/utils/fixPrecisionAndFormat";

const TileInfo = ({ tile_count, tilePrice }) => {
  const list = [
    { title: "Selected Tiles", info: tile_count },
    {
      title: "Value",
      info: `$ ${fixPrecisionAndFormat(tile_count * tilePrice, 2)}`,
    },
    {
      title: "Current Value per tiles",
      info: `$ ${tilePrice}`,
    },
  ];
  return (
    <div className="flex flex-col gap-2 mt-10 p-3 bg-dashboard-frame rounded-[20px]">
      {list.map((item, index) => (
        <div key={index} className="flex justify-between items-center">
          <div className="text-body-md md:text-label-md text-blackshade-200">
            {item.title}
          </div>
          <div className="text-label-sm md:text-label-lg text-white">
            {item.info}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TileInfo;
