import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

interface LandStateContextProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  showStepper: boolean;
  setShowStepper: (value: boolean) => void;
  tokenPercentageValue: number;
  setTokenPercentageValue: (value: number) => void;
  selectedTokenValue: string;
  setSelectedTokenValue: (value: string) => void;
  fetchLandData: any;
  setFetchLandData: (data: any) => void;
  fetchLandError: boolean;
  setFetchLandError: (value: boolean) => void;
  isFetchingLand: boolean;
  setIsFetchingLand: (value: boolean) => void;
}

const LandStateContext = createContext<LandStateContextProps | undefined>(
  undefined
);

export const LandStateProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedTokenValue, setSelectedTokenValue] = useState("PME");
  const [tokenPercentageValue, setTokenPercentageValue] = useState(50);

  const [fetchLandData, setFetchLandData] = useState<any>(null);
  const [fetchLandError, setFetchLandError] = useState(false);
  const [isFetchingLand, setIsFetchingLand] = useState(false);
  return (
    <LandStateContext.Provider
      value={{
        isLoading,
        setIsLoading,
        showStepper,
        setShowStepper,
        tokenPercentageValue,
        setTokenPercentageValue,
        selectedTokenValue,
        setSelectedTokenValue,
        fetchLandData,
        setFetchLandData,
        fetchLandError,
        setFetchLandError,
        isFetchingLand,
        setIsFetchingLand,
      }}
    >
      {children}
    </LandStateContext.Provider>
  );
};

export const useLandState = () => {
  const context = useContext(LandStateContext);
  if (!context) {
    throw new Error("useLandState must be used within a LandStateProvider");
  }
  return context;
};
